import React from 'react'
import { PageProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Layout } from '../component/Layout'
import ContactHero from '../component/page_component/contact/contactHero'
import ContactForm from '../component/page_component/contact/contactForm'
import JoinUs from '../component/page_component/contact/joinUs'
import SeoContent from '../component/page_component/SeoContent'
import NewsLetter from '../component/page_component/contact/newsLetter'
import { Box } from '@material-ui/core'
import { Cinemagraph } from '../component/Cimenegraph'

const useStyles = makeStyles((theme) => ({}))

export const Contact: React.FC<PageProps> = (props) => {
  const { location } = props
  const intl = useIntl()
  const classes = useStyles()

  return (
    <Layout
      showFlotingMenu={false}
      maxWidth={false}
      location={location}
      title={intl.formatMessage({ id: 'Kretz', defaultMessage: 'Kretz' })}
    >
      <Box style={{ marginBottom: '80px' }}>
        <ContactHero />
        <ContactForm />
        <JoinUs />
        {/* <NewsLetter /> */}
        {/* <SeoContent /> */}
      </Box>
      <Cinemagraph flixelId="jtsueywkv381kalwb9l5" flixelMobileId="7od2xokpjecfjrq2jgsh" />
    </Layout>
  )
}

export default Contact
