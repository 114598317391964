import React, { useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'

import styled from 'styled-components'
import useInView from 'react-cool-inview'
import { Link as GatsbyLink } from 'gatsby'

import { Box, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import LogoSvg from '../../../icons/icon.svg'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Facebook from '@material-ui/icons/Facebook'
import Instagram from '@material-ui/icons/Instagram'
import YouTube from '@material-ui/icons/YouTube'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import LocalPhoneIcon from '@material-ui/icons/LocalPhone'

const ImgContainer = styled.div`
  .gatsby-image-wrapper {
    width: 50%;
  }
  @media (max-width: 1279.95px) and (min-width: 960px) {
    .gatsby-image-wrapper {
      width: 60%;
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '2em 0em',
      height: '80dvh',
    },
  },
  innerDiv: {
    minHeight: '512px',
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    padding: '0em 1em',
    [theme.breakpoints.up('lg')]: {
      width: '75vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },

  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '58px',
    paddingInline: '3rem',
    fontSize: theme.typography.pxToRem(52),
    fontWeight: 400,
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(35),
      paddingInline: '24px',
      marginInline: '24px',
      textAlign: 'center',
    },
  },
  detail: {
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItem: 'center',
      flexDirection: 'column',
      gap: '1rem',
    },
  },
  itemGrid: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginInline: '3rem',
    },
  },
  logo: {
    height: '3em',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      height: '2em',
    },
  },
  logoContainer: {
    width: '100%',
    marginBlock: '2rem',
    justifyContent: 'center',
  },
  ml1: {
    marginInline: '0.5em',
  },
  linksText: {
    fontFamily: `'Gotham Light', serif`,
    fontSize: '14px',
    fontWeight: 325,
    lineHeight: '16.8px',
    color: theme.palette.text.primary,
    textDecoration: 'unset',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))
const linkedInIconPathData =
  'M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M9,17H6.477v-7H9 V17z M7.694,8.717c-0.771,0-1.286-0.514-1.286-1.2s0.514-1.2,1.371-1.2c0.771,0,1.286,0.514,1.286,1.2S8.551,8.717,7.694,8.717z M18,17h-2.442v-3.826c0-1.058-0.651-1.302-0.895-1.302s-1.058,0.163-1.058,1.302c0,0.163,0,3.826,0,3.826h-2.523v-7h2.523v0.977 C13.93,10.407,14.581,10,15.802,10C17.023,10,18,10.977,18,13.174V17z'

export const ContactHero = () => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.innerDiv}>
        <Grid item xs={12}>
          <Grid item xs={12} className={classes.logoContainer}>
            <img src={LogoSvg} alt="Logo Kretz" className={classes.logo} />
          </Grid>
          <Grid className={classes.title}>
            <span>{intl.formatMessage({ id: 'new.Contact' })}</span>
          </Grid>
        </Grid>
        <Grid container xs={12} md={12} className={classes.detail}>
          <Grid item lg={3} md={3} className={classes.itemGrid}>
            <Box className={classes.linksText}>
              {intl.formatMessage({ id: 'legalNotice.address' })}
            </Box>
          </Grid>
          <Grid item lg={3} md={3} className={classes.itemGrid}>
            <Box>
              <Link
                href={`mailto:contact%40kretzrealestate.com`}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
                className={classes.linksText}
              >
                <AlternateEmailIcon fontSize="small" />
                {intl.formatMessage({ id: 'contact@kretzrealestate.com' })}
              </Link>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} className={classes.itemGrid}>
            <Box>
              <Link
                href={`tel:+33141109122`}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
                className={classes.linksText}
              >
                <LocalPhoneIcon fontSize="small" />
                {intl.formatMessage({ id: '+33 (0)1 41 10 91 22' })}
              </Link>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} className={classes.itemGrid}>
            <Box>
              <OutboundLink
                href="https://fr.linkedin.com/company/kretz-family-real-estate"
                className={classes.linksText}
                target="_blank"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  style={{ fontSize: '1.5rem' }}
                  className={classes.ml1}
                >
                  <path d={linkedInIconPathData} />
                </svg>
              </OutboundLink>
              <OutboundLink
                href="https://www.instagram.com/kretzfamilyrealestate/"
                className={classes.linksText}
                target="_blank"
              >
                <Instagram className={classes.ml1} />
              </OutboundLink>
              <OutboundLink
                href="https://www.facebook.com/kretzfamilyrealestate"
                className={classes.linksText}
                target="_blank"
              >
                <Facebook className={classes.ml1} />
              </OutboundLink>
              <OutboundLink
                href="https://www.facebook.com/kretzfamilyrealestate"
                className={classes.linksText}
                target="_blank"
              >
                <YouTube className={classes.ml1} />
              </OutboundLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ContactHero
