import React, { useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'

import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import { Box, Link, Typography } from '@material-ui/core'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { CustomButton } from '../../CustomButton'

const ImgContainer = styled.div`
  .gatsby-image-wrapper {
    width: 50%;
  }
  @media (max-width: 1279.95px) and (min-width: 960px) {
    .gatsby-image-wrapper {
      width: 60%;
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '4em 2em',
    },
  },
  innerDiv: {
    paddingBlock: theme.spacing(15),
    width: '55vw',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    [theme.breakpoints.between('xs', 'md')]: {
      flexBasis: 'unset',
      width: '100%',
      paddingBlock: 0,
    },
  },

  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '58px',
    paddingInline: '3rem',
    fontSize: theme.typography.pxToRem(52),
    fontWeight: 400,
    paddingBlock: theme.spacing(2),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(35),
    },
  },
  metaTitle: {
    fontFamily: `'Gotham book', serif`,
    fontWeight: 325,
    textTransform: 'uppercase',
    textAlign: 'center',
    justifyContent: 'center',
  },
  detail: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '38px',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 400,
    paddingBlock: theme.spacing(5),
    [theme.breakpoints.between('md', 'md')]: {
      justifyContent: 'center',
      width: '100%',
    },
  },
  logo: {
    height: '3em',
    color: '#ffffff',
  },
  logoContainer: {
    width: '100%',
    marginBlock: '2rem',
  },
  ml1: {
    marginLeft: '1em',
  },
  linksText: {
    fontFamily: `'Gotham Light', serif`,
    fontSize: '14px',
    fontWeight: 325,
    lineHeight: '16.8px',
    color: theme.palette.text.primary,
    textDecoration: 'unset',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '55vw',
    [theme.breakpoints.only('md')]: {
      marginBottom: '1rem',
      paddingInline: '17%',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '1rem',
      width: '100%',
    },
  },
}))

export const joinUs = () => {
  const classes = useStyles()
  const intl = useIntl()
  const ref: any = useRef()

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.innerDiv}>
        <Grid item xs={12}>
          <Grid className={classes.title}>
            <span>{intl.formatMessage({ id: 'new.Contact_3' })}</span>
          </Grid>
          <Grid container spacing={3} className={classes.metaTitle}>
            <Grid item md={6} lg={12}>
              {intl.formatMessage({ id: 'new.Contact_4' })}
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.detail}>
            <Grid item md={6} lg={12}>
              {intl.formatMessage({ id: 'new.Contact_5' })}
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} md={12} className={classes.btnContainer}>
          <Grid item md={6} xs={12}>
            <CustomButton
              width="90%"
              isDarkBackground={true}
              displayIcon
              iconPosition="end"
              icon={() => <ArrowForwardIcon />}
              onClick={() => {
                window.open('https://join-kretz.com', '_blank', 'noopener,noreferrer')
              }}
            >
              <GatsbyLink to={`#`} color="inherit" className={classes.btnLink}>
                <Typography className={classes.btnTxt}>
                  {intl.formatMessage({ id: 'new.Contact_6' })}
                </Typography>
              </GatsbyLink>
            </CustomButton>
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomButton width="90%" isWhiteBackground>
              <GatsbyLink to={`/${intl.locale}/agency`} color="inherit" className={classes.btnLink}>
                <Typography className={classes.btnTxt}>
                  {intl.formatMessage({ id: 'new.Contact_7' })}
                </Typography>
              </GatsbyLink>
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default joinUs
